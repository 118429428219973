import firebase from 'utils/isomorphic-firebase'

export default async function fetchLocale(localeId) {
  if (!localeId) {
    const query = firebase.firestore().collection('config/translations/locales')
    const { docs } = await query.get()

    return docs.reduce(
      (collectionConfigs, doc) => ({
        ...collectionConfigs,
        [doc.id]: doc.data()
      }),
      {}
    )
  }

  const ref = firebase
    .firestore()
    .doc(`config/translations/locales/${localeId}`)

  const snapshot = await ref.get()

  if (!snapshot.exists) {
    throw new Error(`Locale ${localeId} doesnt exist!`)
  }

  return snapshot.data()
}
