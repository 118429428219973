import * as fieldTypes from 'field-types'
import {
  getInitialField,
  getInitialOption
} from 'utils/fieldTypes/getInitialValues'

function getDemoConfig() {
  return Object.entries(fieldTypes).map(([type, options]) => {
    const field = { ...getInitialField(), name: type, type }
    if (options.hasChildren) {
      field.fields = [
        { ...getInitialField(), name: `${type}.text`, type: 'text' },
        { ...getInitialField(), name: `${type}.date`, type: 'date' }
      ]
    }
    if (options.hasOptions) {
      field.data = field.data || {}
      field.data.options = [
        { ...getInitialOption(), name: 'A' },
        { ...getInitialOption(), name: 'B' },
        { ...getInitialOption(), name: 'C' }
      ]
    }

    if (options.hasData && type === 'reference') {
      field.data = field.data || {}
      Object.assign(field.data, {
        displayField: ['email'],
        collection: 'users'
      })
    }
    if (options.hasData && type === 'simple-list') {
      field.data = field.data || {}
      Object.assign(field.data, {
        field: { ...getInitialField(), name: 'text', type: undefined },
        type: 'text'
      })
    }
    return field
  })
}

export default getDemoConfig
