import firebase from 'utils/isomorphic-firebase'
import getDemoConfig from 'utils/fieldTypes/getDemoConfig'

async function translateCollectionConfig(doc, locale) {
  if (!locale) return doc.data()
  const localeDoc = await doc.ref
    .collection('translations')
    .doc(locale)
    .get()
  return {
    translations: localeDoc.data() || {},
    ...doc.data()
  }
}

export default async function fetchCollectionConfig({
  collectionId,
  locale
} = {}) {
  if (!collectionId) {
    const query = firebase.firestore().collection(`collections`)
    const { docs } = await query.get()

    return docs.reduce(async (collectionConfigs, doc) => {
      const demo = doc.id === 'test'
      const collectionConfig = await translateCollectionConfig(doc, locale)
      if (demo) {
        collectionConfig.fields = getDemoConfig()
      }
      return {
        ...(await collectionConfigs),
        [doc.id]: collectionConfig
      }
    }, {})
  }

  const ref = firebase.firestore().doc(`collections/${collectionId}`)

  const snapshot = await ref.get()

  if (!snapshot.exists) {
    throw new Error(`Collection ${collectionId} doesnt exist!`)
  }

  return translateCollectionConfig(snapshot, locale)
}
