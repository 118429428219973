import generate from 'nanoid/generate'

const nanoid = () => generate('1234567890abcdef', 6)

export function getInitialField() {
  return {
    id: nanoid(),
    name: '',
    type: 'text',
    data: {},
    disabled: false,
    translatable: false,
    columnOptions: {
      sort: false,
      display: true
    },
    editorOptions: {
      showHistory: false,
      helperText: false
    }
  }
}

export function getInitialOption() {
  return {
    id: nanoid(),
    name: ''
  }
}
