import React, { useState, useMemo, useContext, useEffect, useRef } from 'react'
import { serializer } from 'utils/isomorphic-firebase'
import { useUser } from 'components/UserContext'
import { useDocument } from '@sb-konzept/firebase-hooks'
import fetchCollectionConfig from 'utils/fetchCollectionConfig'
import fetchLocale from 'utils/firebase/fetchLocale'

export const AppContext = React.createContext({ user: null })

export function useApp() {
  const user = useContext(AppContext)
  return user
}

export function AppProvider(props) {
  const {
    children,
    collectionConfig,
    title,
    firebaseUser,
    locale,
    roles
  } = props

  const user = useUser()
  const { value: rtFirebaseUser } = useDocument(
    `collections/users/entities/${user?.uid}`
  )

  const lastCtxValue = useRef()

  const [rtCollectionConfig, setRtCollectionConfig] = useState()
  const [rtLocale, setRtLocale] = useState()

  const ctxValue = useMemo(() => {
    // console.debug('appctx update')
    return {
      collectionConfig:
        rtCollectionConfig || serializer.deserialize(collectionConfig),
      firebaseUser:
        typeof rtFirebaseUser === 'undefined'
          ? serializer.deserialize(firebaseUser)
          : rtFirebaseUser,
      roles: serializer.deserialize(roles),
      title,
      locale: rtLocale || locale
    }
  }, [
    collectionConfig,
    firebaseUser,
    locale,
    roles,
    rtCollectionConfig,
    rtFirebaseUser,
    rtLocale,
    title
  ])

  useEffect(() => {
    async function refreshCollectionConfig() {
      console.debug('refresh collection config')
      setRtCollectionConfig(
        await fetchCollectionConfig({
          locale: ctxValue.firebaseUser?.language || 'en'
        })
      )
    }
    async function refreshLocale() {
      console.debug('refresh locale')
      setRtLocale(await fetchLocale(ctxValue.firebaseUser?.language || 'en'))
    }
    if (!lastCtxValue.current) {
      lastCtxValue.current = ctxValue
      return // before ctxValue was initially computed
    }
    if (
      ctxValue.firebaseUser?.language !==
      lastCtxValue.current.firebaseUser?.language
    ) {
      console.debug('language changed')
      refreshCollectionConfig()
      refreshLocale()
    }

    lastCtxValue.current = ctxValue
  }, [ctxValue, ctxValue.firebaseUser])
  return <AppContext.Provider value={ctxValue}>{children}</AppContext.Provider>
}
